import request from "@/services/request"
import baseUrl from "@/services/baseUrl"

// let path =  `${baseUrl.main}/${baseUrl.envName != 'development'? "cs/": ''}cs/v1/`;
// let path = `http://localhost:6050/v1/`;
let path = `${baseUrl.main}/v1/`;

// this is for globally used APIs only
export default {
  getCompanies: (params = {}) => request.post(path + "companies/bo/list?mode=" + params.mode + '&limit=10&pageNum=' + params.pageNum, params),
  getRequests: (params = {}) => request.post(path + "companies/bo/list-request?limit=10&pageNum=" + params.pageNum, params),
  addCompany: (params = {}) => request.post(path + "companies/bo/new", params),
  editCompany: (params = {}) => request.put(path + "companies/bo/" + params.companyId, params),
  submitQuestion: (params = {}) => request.put(path + "companies/bo/question/" + params.companyId, params),
  toggleVisibility: (params = '') => request.post(path + "companies/bo/toggle/" + params),
  toggleRequestStatus: (params = '') => request.post(path + "companies/bo/toggle-request-response/" + params),
  toggleQuestionsVisibility: (params = '') => request.post(path + "companies/bo/show-request/" + params),

  getCategories: (params = {}, hideLoadingSpinner = false, hideErrorMsg = false) => request.post(path + "companies/bo/type/list", params, {hideLoadingSpinner, hideErrorMsg}),
  addCategory: (params = {}) => request.post(path + "companies/bo/type/new", params),
  toggleCategoryVisibility: (params = '') => request.post(path + "companies/bo/type/toggle/" + params),
  removeCategory: (params = {}) => request.put(path + "companies-category/bo/remove/" + params._id),
  editCategory: (params = {}) => request.put(path + "companies/bo/type/" + params.typeId, params),
}